/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1707"
  }, "Git의 새로운 Merge 전략 ort")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.jquery.com/2024/02/06/jquery-4-0-0-beta/"
  }, "jQuery 4.0 beta 출시!"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/jquery/jquery/pull/5077"
  }, "jQuery의 IE 지원 중단 PR")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.dongascience.com/news.php?idx=63696"
  }, "GitHub CEO였던 Nat Friedman이 후원하는 폼페이 두루마리 해독 프로젝트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-vision-pro/"
  }, "애플 비전프로")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnbc.com/2024/02/09/openai-ceo-sam-altman-reportedly-seeking-trillions-of-dollars-for-ai-chip-project.html"
  }, "샘 알트먼이 반도체 공장을 짓기 위해 수조 달러 투자 활동 중")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
